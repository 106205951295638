import React from "react";

const StatusOngoing = () => {
    return (
        <div>
            <a className="text-warning text-xs mb-0" href="javascript:;">
                Sedang Diverifikasi oleh Dokter
            </a>
        </div>
    );
};

export default StatusOngoing;