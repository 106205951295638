import React from "react";

const StatusVerified = () => {
  return (
    <div>
      <a className="text-success text-xs mb-0" href="javascript:;">
        Telah Diverifikasi Oleh Dokter
      </a>
    </div>
  );
};

export default StatusVerified;
