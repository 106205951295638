import React from "react";

const Ongoing = () => {
    return (
        <div>
            <a className="text-warning text-xs mb-0" href="javascript:;">
                <i
                    className="fa fa-spinner text-xs me-2"
                    aria-hidden="true"
                ></i>
                Sedang Diverifikasi
            </a>
        </div>
    );
};

export default Ongoing;
