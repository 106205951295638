// ListOfCity.js
export const ListOfCity = {
    jatim: [
        { city: "Bangkalan" },
        { city: "Banyuwangi" },
        { city: "Blitar" },
        { city: "Bojonegoro" },
        { city: "Bondowoso" },
        { city: "Gresik" },
        { city: "Jember" },
        { city: "Jombang" },
        { city: "Kediri" },
        { city: "Lamongan" },
        { city: "Lumajang" },
        { city: "Madiun" },
        { city: "Magetan" },
        { city: "Malang" },
        { city: "Mojokerto" },
        { city: "Nganjuk" },
        { city: "Ngawi" },
        { city: "Pacitan" },
        { city: "Pamekasan" },
        { city: "Pasuruan" },
        { city: "Ponorogo" },
        { city: "Probolinggo" },
        { city: "Sampang" },
        { city: "Sidoarjo" },
        { city: "Situbondo" },
        { city: "Sumenep" },
        { city: "Trenggalek" },
        { city: "Tuban" },
        { city: "Tulungagung" },
        { city: "Kota Batu" },
        { city: "Kota Blitar" },
        { city: "Kota Kediri" },
        { city: "Kota Madiun" },
        { city: "Kota Malang" },
        { city: "Kota Mojokerto" },
        { city: "Kota Pasuruan" },
        { city: "Kota Probolinggo" },
        { city: "Kota Surabaya" },
    ],
    jabar: [
        { city: "Kab. Bandung" },
        { city: "Kab. Bandung Barat" },
        { city: "Kab. Bekasi" },
        { city: "Kab. Bogor" },
        { city: "Kab. Ciamis" },
        { city: "Kab. Cianjur" },
        { city: "Kab. Cirebon" },
        { city: "Kab. Garut" },
        { city: "Kab. Indramayu" },
        { city: "Kab. Karawang" },
        { city: "Kab. Kuningan" },
        { city: "Kab. Majalengka" },
        { city: "Kab. Pangandaran" },
        { city: "Kab. Purwakarta" },
        { city: "Kab. Subang" },
        { city: "Kab. Sukabumi" },
        { city: "Kab. Sumedang" },
        { city: "Kab. Tasikmalaya" },
        { city: "Kota Bandung" },
        { city: "Kota Banjar" },
        { city: "Kota Bekasi" },
        { city: "Kota Bogor" },
        { city: "Kota Cimahi" },
        { city: "Kota Cirebon" },
        { city: "Kota Depok" },
        { city: "Kota Sukabumi" },
        { city: "Kota Tasikmalaya" },
    ],
    jateng: [
        { city: "Kab. Banjarnegara" },
        { city: "Kab. Banyumas" },
        { city: "Kab. Batang" },
        { city: "Kab. Blora" },
        { city: "Kab. Boyolali" },
        { city: "Kab. Brebes" },
        { city: "Kab. Cilacap" },
        { city: "Kab. Demak" },
        { city: "Kab. Grobogan" },
        { city: "Kab. Jepara" },
        { city: "Kab. Karanganyar" },
        { city: "Kab. Kebumen" },
        { city: "Kab. Kendal" },
        { city: "Kab. Klaten" },
        { city: "Kab. Kudus" },
        { city: "Kab. Magelang" },
        { city: "Kab. Pati" },
        { city: "Kab. Pekalongan" },
        { city: "Kab. Pemalang" },
        { city: "Kab. Purbalingga" },
        { city: "Kab. Purworejo" },
        { city: "Kab. Rembang" },
        { city: "Kab. Semarang" },
        { city: "Kab. Sragen" },
        { city: "Kab. Sukoharjo" },
        { city: "Kab. Tegal" },
        { city: "Kab. Temanggung" },
        { city: "Kab. Wonogiri" },
        { city: "Kab. Wonosobo" },
        { city: "Kota Magelang" },
        { city: "Kota Pekalongan" },
        { city: "Kota Salatiga" },
        { city: "Kota Semarang" },
        { city: "Kota Surakarta" },
        { city: "Kota Tegal" },
    ],
    yogya: [
        { city: "Kab. Bantul" },
        { city: "Kab. Gunungkidul" },
        { city: "Kab. Kulon Progo" },
        { city: "Kab. Sleman" },
        { city: "Kota Yogyakarta" },
    ],
    dki: [
        { city: "Kepulauan Seribu" },
        { city: "Jakarta Barat" },
        { city: "Jakarta Pusat" },
        { city: "Jakarta Selatan" },
        { city: "Jakarta Timur" },
        { city: "Jakarta Utara" },
    ],
    banten: [
        { city: "Kab. Lebak" },
        { city: "Kab. Pandeglang" },
        { city: "Kab. Serang" },
        { city: "Kab. Tangerang" },
        { city: "Kota Cilegon" },
        { city: "Kota Serang" },
        { city: "Kota Tangerang" },
        { city: "Kota Tangerang Selatan" },
    ],
};